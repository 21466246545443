<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div v-for="(data,index) of form.categories" :key="'C-'+index" class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/3 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('category'+index)
                  ? errors.first('category'+index)
                  : ''
              "
              :variant="
                showValidation && errors.first('category'+index) ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categorías - Subcategorías'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="data.id_categories"
                :name="'category'+index"
                :id="'category'+index"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="categories"
                textAttribute="name"
                valueAttribute="index"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('category'+index) ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>
          </div>

          <div class="flex md:w-2/3">
            <div class="w-5/6">
              <t-input-group
                :feedback="
                  showValidation && errors.first('academy'+index)
                    ? errors.first('academy'+index)
                    : ''
                "
                :variant="
                  showValidation && errors.first('academy'+index) ? 'danger' : ''
                "
                class="w-full md:ml-2 md:mr-2 my-4"
                :label="'Academias'"
              >
                <t-rich-select
                  v-validate="'required'"
                  data-vv-validate-on="input"
                  v-model="data.academies"
                  :name="'academy'+index"
                  :id="'academy'+index"
                  placeholder="Seleccione una opción"
                  :disabled="loading"
                  noResultsText="No se encontraron resultados"
                  searchBoxPlaceholder="Busca..."
                  :options="academies"
                  textAttribute="name"
                  valueAttribute="index"
                  :multiple="true"
                  :closeOnSelect="false"
                  :clearable="true"
                  :variant="
                    showValidation && errors.first('academy'+index) ? 'danger' : ''
                  "
                ></t-rich-select>
              </t-input-group>
            </div>

            <div class="w-1/6 my-auto mx-auto">
              <icon
                v-if="index > 0"
                style="cursor: pointer; width: 28px; height: 28px"
                icon="times"
                class="rounded-full bg-red-1100 text-white text-3xl p-2 my-auto ml-3"
                @click="removeCategory(index)"
              />
            </div>
          </div>
        </div>

        <div class="w-full flex justify-between">
          <t-button type="button" variant="error" @click="addNewCategory" class="ml-2">
            Nueva categoría
          </t-button>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      form: {
        categories: [
          {
            id_categories: null,
            academies: []
          }
        ]
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    categories() {
      const data = []
      const categories = this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
      const subcategories = this.$store.getters["global/getItems"](
        "subcategory",
        "unpaginated_elements"
      )

      for (let category of categories) {
        for (let subcategory of subcategories) {
          data.push({
            index: category.id + "$$" + subcategory.id,
            id_subcategory: subcategory.id,
            id_category: category.id,
            name: category.name + " - " + subcategory.name
          })
        }
      }
      return data
    },
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      ).map((el) => ({
        index: el.id,
        name: el.name
      }))
    },
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    addNewCategory() {
      this.form.categories.push({
        id_categories: null,
        academies: []
      })
    },
    removeCategory(index) {
      this.form.categories.splice(index, 1)
    },
    getAcademies() {
      this.$store
        .dispatch("global/getItems", {
          module: 'academy',
          route: '/academy/all',
          noPaginate: true,
          isMaster: false,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc",
            verified: 1
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.showValidation = false
          this.$emit("save")
        }
      })
    },
  },
  created() {
    this.getItems("/category/all", "category", true)
    this.getItems("/subcategory/all", "subcategory", true)
    this.getAcademies()
  }
}
</script>
