<template>
  <div>
    <Form
      title="Añadir categorías"
      ref="tournamentForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.tournamentForm.form
      this.loading = true

      form.categories = form.categories.map((x) => ({
        id_category: x.id_categories.split("$$")[0],
        id_subcategory: x.id_categories.split("$$")[1],
        academies: x.academies
      }))

      this.$store
        .dispatch("global/create", {
          payload: {
            id_tournament: this.$route.params.id,
            categories: JSON.stringify(form.categories)
          },
          route: "/tournament_category/save_many",
          module: "tournamentCategory"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/torneos/"+this.$route.params.id+"/categorias")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
